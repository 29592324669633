import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { DocumentViewerState } from './state';

const getters: GetterTree<DocumentViewerState, RootState> = {
  autorizacionPdfUrl: (state) => state.autorizacionPdfUrl,
  instruccionesPdfUrl: (state) => state.instruccionesPdfUrl,
  pagarePdfUrl: (state) => state.pagarePdfUrl,
};

export default getters;