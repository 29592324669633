import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { DocumentViewerState } from './state';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

const actions: ActionTree<DocumentViewerState, RootState> = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async fetchDocumentUrls({ commit }, { document, type }: { document: string; type: string }) {
      try {
        const response = await axios.get('https://creditosb.aikacree.com/api/auth/view-document-autorization', {
            params: { document, type },
            headers: {
                Authorization: `Bearer ${Cookies.get('authToken')}` // Si se requiere autenticación
            },
            responseType: 'blob'
        });

        const pdfUrl = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));

        if (type === 'autorizacion') {
            commit('setAutorizacionPdfUrl', pdfUrl);
        } else if (type === 'instrucciones') {
            commit('setInstruccionesPdfUrl', pdfUrl);
        } else if (type === 'pagare') {
            commit('setPagarePdfUrl', pdfUrl);
        }
    } catch (error) {
        console.error('Error fetching document URL:', error);
    }
},
};
  
  export default actions;