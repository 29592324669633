import { MutationTree } from 'vuex';
import { DocumentUploadState } from './state';

const mutations: MutationTree<DocumentUploadState> = {
    setDocumentUrls(state, payload: { documentUrl: string; nominaUrl: string}) {
        state.documentUrl = payload.documentUrl;
        state.nominaUrl = payload.nominaUrl;
    },
};

export default mutations;