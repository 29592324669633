import { Module } from 'vuex';
import { RootState } from '../types';
import state, { documentSignSendotpState } from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';


const documentSignSendotp: Module<documentSignSendotpState, RootState> = {
    state,
    actions,
    mutations,
    getters
};

export default documentSignSendotp;