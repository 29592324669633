import { Module } from 'vuex';
import { RootState } from '../types';
import state, { SimulateCreditState } from './state';
import actions from './actions';
import mutations from './mutations';

const simulateCredit: Module<SimulateCreditState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
};
export default simulateCredit;