export interface SimulateCreditState {
    amount: number | null;
    installments: number | null;
    days: number | null;
    simulationResult: {
        loan_amount: number;
        monthly_salary: number;
        term_months: number;
        total_interest: number;
        total_amount: number;
        monthly_payment: number;
        payment_due_date: string;
    } | null;
}

const state: SimulateCreditState = {
    amount: 0, // Valores iniciales, ajusta según tu lógica
    installments: 0,
    days: 0,
    simulationResult: null,
};

export default state;