import { GetterTree } from 'vuex';
import { documentSignSendotpState } from './state';
import { RootState } from '../types';


export const getters: GetterTree<documentSignSendotpState, RootState> = {
    document: (state) => state.document,
    otp: (state) => state.otp,
    token: (state) => state.token,
};

export default getters;