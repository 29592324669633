import { Module } from 'vuex';
import { RootState } from '../types';
import { DocumentSignState } from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import { reactive } from 'vue'; 

const state = reactive<DocumentSignState>({
    verificationStatus: null,
});

const documentSign: Module<DocumentSignState, RootState> = {
    state,
    actions,
    mutations,
    getters
}

export default documentSign;