import { MutationTree } from 'vuex';
import { AuthState, User } from './state';


const mutations: MutationTree<AuthState> = {
    SET_DOCUMENT(state, document) {
        state.document = document; // Ajustar para usar state.value
    },
    SET_TOKEN(state, token: string) {
        state.token = token;
        state.isAuthenticated = !!token;
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },
    SET_USER(state, user: User) {
        state.user = user;
    },
    LOGOUT(state) {
      state.token = null;
      state.user = null;
      state.document = null;
      state.isAuthenticated = false;
      
    },
    
};

export default mutations;