import { Module } from 'vuex';
import { RootState } from '../types';
import state, { DocumentViewerState } from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const documentViewer: Module<DocumentViewerState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default documentViewer;