import { GetterTree } from 'vuex';
import { AuthState } from './state';
import { RootState } from '@/store/types';


const getters: GetterTree<AuthState, RootState> = {
    isAuthenticated: state => state.isAuthenticated,
    user: (state) => state.user,
};

export default getters;