import { MutationTree } from 'vuex';
import { documentSignSendotpState } from './state';

export const mutations: MutationTree<documentSignSendotpState> =  {
  SET_DOCUMENT(state, document: string) {
    state.document = document;
  },
  SET_OTP(state, otp: string) {
      state.otp = otp;
  }
};

export default mutations;