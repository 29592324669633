import { Module } from 'vuex';
import { AuthState } from './state';
import { RootState } from '@/store/types';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { reactive } from 'vue'; 

const state = reactive<AuthState>({
    token: null,
    user: null,
    document: null,
    isAuthenticated: false,
});

const auth: Module<AuthState, RootState> = {
    actions,
    getters,
    mutations,
    state,
}


export default auth;