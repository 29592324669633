import Cookies from "js-cookie";

export interface documentSignSendotpState {
    document: string;
    otp: string;
    token: string;
  }
  
  export const state: documentSignSendotpState = {
    document: '',
    otp: '',
    token: Cookies.get('authToken') || '',
  };

export default state;