import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../types';
import { documentSignSendotpState } from './state';
import axios, { AxiosResponse }  from 'axios';
import Cookies from 'js-cookie';
import { log } from 'console';

const actions: ActionTree<documentSignSendotpState, any> = {
    async sendOtp({ commit }, payload: { document: string }){
        try {
            // Obtener el token de las cookies
            const token = Cookies.get('authToken');
            if (!token) {
                throw new Error('No se encontró el token de autenticación');
            }

            // Configurar Axios con el token de autenticación
            axios.defaults.headers.common['Authorization'] = `Token ${token}`;
            axios.defaults.withCredentials = true;

            // Realizar la solicitud al endpoint de send-otp
            const response = await axios.post('https://creditosb.aikacree.com/api/auth/send-otp/', 
                { document: payload.document }, 
                { headers: { 'Content-Type': 'application/json'} }
            );

            // Verificar si la respuesta fue exitosa
            if (response.status === 200) {
                const newToken = response.data.token;
                if (newToken) {
                    Cookies.set('authToken', newToken);
                    commit('SET_TOKEN', newToken, { root: true });
                    commit('SET_DOCUMENT', payload.document);
                    axios.defaults.headers.common['Authorization'] = `Token ${newToken}`;
                }
                return { success: true, message: response.data.message };
            } else {
                throw new Error(response.data.error || 'Error desconocido en la respuesta del servidor');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            return { success: false, error: error instanceof Error ? error.message : 'Unknown error' };
        }
    },

};

export default actions;