export interface DocumentViewerState {
  autorizacionPdfUrl: string | null;
  instruccionesPdfUrl: string | null;
  pagarePdfUrl: string | null;
  document: string | null;
}

const state: DocumentViewerState = {
  autorizacionPdfUrl: null,
  instruccionesPdfUrl: null,
  pagarePdfUrl: null,
  document: null,
};

export default state;