import { Module } from 'vuex';
import { RootState } from '../types';
import state, { DocumentUploadState } from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const documentUpload: Module<DocumentUploadState, RootState> = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};

export default documentUpload;