import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { RootState } from './types';  // Asegúrate de que estás importando RootState desde el archivo correcto
import auth from './authentication-user';
import simulateCredit from './simulate-credit';
import documentUpload from './document-upload';
import Cookies from 'js-cookie'; // Importa js-cookie para manejar las cookies
import router from '@/router'; // Asegúrate de importar tu enrutador adecuadamente
import documentViewer from './document-viewer';
import documentSign from './document-sign';
import documentSignSendotp from './document-sign-sendotp';

const store = createStore<RootState>({
  modules: {
    auth,
    simulateCredit,
    documentUpload,
    documentViewer,
    documentSignSendotp,
    documentSign,
  },
  // Middleware para manejar la autenticación y redirección
  plugins: [store => {
    store.subscribeAction(async (action) => {
      // Verificar el token al ejecutar acciones, excepto el logout
      if (action.type !== 'auth/logout') {
        const token = Cookies.get('authToken');
        if (!token) {
          // Redirigir a la página de inicio de sesión si no hay token
          router.push('/login');
        }
      }
    });
  }]
});

export function useStore(): Store<RootState> {
  return baseUseStore();
}


export default store;