import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import BootstrapVueNext from 'bootstrap-vue-next';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


async function init() {
    // Recuperar el token del almacenamiento local
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
        store.commit('auth/SET_TOKEN', storedToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
    }


    // Crear la aplicación Vue y montar el router y el store
    const app = createApp(App);
    app.use(router);
    app.use(store);
    app.use(VueAxios, axios);
    app.use(BootstrapVueNext);

    // Montar la aplicación en el DOM
    app.mount('#app');
}

// Iniciar la aplicación
init();